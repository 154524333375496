import {IPlatformAPI} from '@wix/native-components-infra/dist/src/types/types'
import {PlanList} from '@wix/wix-events-commons-statics/dist/types/exported-types'
import {RsvpStatus} from '@wix/wix-events-commons-statics'
import {DETAILS_ROUTE} from '../../commons/constants/navigation'
import {CommonState, CommonStoreExtraArgs, DemoEvents, InstanceState} from '../../commons/types/state'
import {
  applyMembershipDiscount,
  checkout,
  clearCheckout,
  editStep,
  getDiscount,
  handleNextStep,
  nextFormClicked,
  setExpandedTicketIndex,
  setTicketDetails,
  setUseBuyerDetails,
  setValidPaymentAdded,
  submitCheckoutStep,
} from '../actions/checkout'
import {publishComponentSettings, updateStyleParams} from '../actions/component'
import {applyCoupon, resetCouponCode, setCouponCode} from '../actions/coupon'
import {getMembers, shareEvent} from '../actions/event'
import {clearInvoice} from '../actions/invoice'
import {detailsPageLoaded, formPageLoaded, thankYouPageLoaded, ticketedThankYouPageLoaded} from '../actions/loaded'
import {ensureLoginForMembersOnly, openMembersModal, promptLogin} from '../actions/members'
import {toggleAgreePolicies, openPolicyModal} from '../actions/policies'
import {
  openCantCompletePaymentModal,
  openCheckoutUnavailable,
  openTicketsDetailsModal,
  openTimeExpiredModal,
} from '../actions/modals'
import {
  navigate,
  navigateToChangeRsvp,
  navigateToDetails,
  navigateToForm,
  navigateToMainPage,
  navigateToOrder,
  navigateToTicketsPicker,
} from '../actions/navigation'
import {downloadTickets} from '../actions/order-success'
import {applyPlan, getPurchasedPlanList, openMembershipPicker} from '../actions/paid-plans'
import {paymentMethodSelected, placeOrderButtonClicked} from '../actions/payment'
import {placeOrder, updateOrder} from '../actions/placed-order'
import {registrationButtonClicked} from '../actions/registration'
import {cancelReservation, reserveTickets} from '../actions/reservation'
import {
  changeMemberRsvp,
  handleRSVP,
  resetRsvpError,
  sendRsvp,
  updateRsvp,
  editRsvpStep,
  submitRsvpStep,
  handleNextRsvpStep,
  changeResponse,
} from '../actions/rsvp'
import {selectTicket, unselectTicket} from '../actions/selected-tickets'
import {updateSettings} from '../actions/settings'
import {collapseDescription, expandDescription} from '../actions/tickets-picker'
import {FormStep} from '../constants/constants'
import {Api} from '../utils/api'
import {setFormFactor} from '../../commons/actions/environment'
import {RsvpErrorMetadata} from './errors'
import {FedopsLogger} from './fedops'
import {RegFormData} from './registration'
import {Alignment, ButtonStyle, ComponentTexts, ImagePosition, ImageRatio} from './settings'

export interface Actions {
  appLoaded: Function
  navigateToForm: typeof navigateToForm
  navigateToMainPage: typeof navigateToMainPage
  detailsPageLoaded: typeof detailsPageLoaded
  formPageLoaded: typeof formPageLoaded
  thankYouPageLoaded: typeof thankYouPageLoaded
  ticketedThankYouPageLoaded: typeof ticketedThankYouPageLoaded
  changeMemberRsvp: typeof changeMemberRsvp
  registrationButtonClicked: typeof registrationButtonClicked
  updateStyleParams: typeof updateStyleParams
  setCouponCode: typeof setCouponCode
  resetCouponCode: typeof resetCouponCode
  resetRsvpError: typeof resetRsvpError
  sendRsvp: typeof sendRsvp
  updateRsvp: typeof updateRsvp
  reserveTickets: typeof reserveTickets
  selectTicket: typeof selectTicket
  unselectTicket: typeof unselectTicket
  ensureLoginForMembersOnly: typeof ensureLoginForMembersOnly
  expandDescription: typeof expandDescription
  collapseDescription: typeof collapseDescription
  getMembers: typeof getMembers
  promptLogin: typeof promptLogin
  openMembershipPicker: typeof openMembershipPicker
  navigateToTicketsPicker: typeof navigateToTicketsPicker
  openCheckoutUnavailable: typeof openCheckoutUnavailable
  placeOrder: typeof placeOrder
  updateOrder: typeof updateOrder
  paymentMethodSelected: typeof paymentMethodSelected
  placeOrderButtonClicked: typeof placeOrderButtonClicked
  navigateToDetails: typeof navigateToDetails
  nextFormClicked: typeof nextFormClicked
  cancelReservation: typeof cancelReservation
  openTicketsDetailsModal: typeof openTicketsDetailsModal
  applyCoupon: typeof applyCoupon
  getPurchasedPlanList: typeof getPurchasedPlanList
  applyPlan: typeof applyPlan
  handleRSVP: typeof handleRSVP
  checkout: typeof checkout
  submitCheckoutStep: typeof submitCheckoutStep
  editStep: typeof editStep
  openTimeExpiredModal: typeof openTimeExpiredModal
  openCantCompletePaymentModal: typeof openCantCompletePaymentModal
  setValidPaymentAdded: typeof setValidPaymentAdded
  navigate: typeof navigate
  setUseBuyerDetails: typeof setUseBuyerDetails
  setTicketDetails: typeof setTicketDetails
  clearInvoice: typeof clearInvoice
  navigateToOrder: typeof navigateToOrder
  navigateToChangeRsvp: typeof navigateToChangeRsvp
  setExpandedTicketIndex: typeof setExpandedTicketIndex
  handleNextStep: typeof handleNextStep
  openMembersModal: typeof openMembersModal
  updateSettings: typeof updateSettings
  publishComponentSettings: typeof publishComponentSettings
  clearCheckout: typeof clearCheckout
  getDiscount: typeof getDiscount
  applyMembershipDiscount: typeof applyMembershipDiscount
  downloadTickets: typeof downloadTickets
  shareEvent: typeof shareEvent
  toggleAgreePolicies: typeof toggleAgreePolicies
  openPolicyModal: typeof openPolicyModal
  editRsvpStep: typeof editRsvpStep
  submitRsvpStep: typeof submitRsvpStep
  handleNextRsvpStep: typeof handleNextRsvpStep
  changeResponse: typeof changeResponse
  setFormFactor: typeof setFormFactor
}

export interface State extends CommonState {
  event: ExtendedEvent
  demoEvents: DemoEvents
  navigation: Navigation
  component: Component
  placedOrder: PlacedOrderState
  currentMemberDetails: CurrentMemberDetails
  membersAreaEnabled: boolean
  memberRsvp: MemberRsvp
  reservation: Reservation
  checkoutOptions: CheckoutOptionsState
  selectedTickets: SelectedTickets
  pendingRequests: PendingRequests
  tickets: wix.events.ticketing.TicketDefinition[]
  ticketsPicker: TicketsPicker
  paidPlans: PaidPlansState
  instance: InstanceState
  checkout: CheckoutState
  rsvp: RsvpState
  invoice: InvoiceState
  calendarLinks: wix.events.CalendarLinks
  guest: wix.events.rsvp.Rsvp
  policies: PoliciesState
}

export interface PoliciesState {
  agreed: boolean
  policies: wix.events.Policy[]
  policyAgreementToken: string
  showAlert: boolean
}

export interface OrderedTicket extends wix.events.ticketing.TicketDefinition {
  orderedCount: number
}

export interface InvoiceState {
  error: string | null
  invoice: wix.events.ticketing.Invoice
  coupon: InvoiceCouponState
}

interface InvoiceCouponState {
  code: string
  ticketId: string
  submitted: boolean
}

export interface CheckoutState {
  useBuyerDetails: boolean
  currentStep: FormStep
  validPaymentAdded: boolean
  buyerDetails: RegFormData
  ticketsDetails: RegFormData[]
  validTicketsDetails: boolean[]
  expandedTicketIndex: number
}

export interface RsvpState {
  currentStep: FormStep
  rsvpDetails: RegFormData
  response: RsvpStatus
}

export interface PlacedOrderState {
  order: wix.events.ticketing.Order
  error: boolean
}

export interface PaidPlansState {
  planList: PlanList
  purchasedPlanList: PlanList
  selectedPaidPlanOrderId: string
}

export interface Reservation {
  data: ReservationData
  error: boolean
}

interface ReservationData {
  id: string
  expires: string
}

export type GetState = () => State

export interface StoreExtraArgs extends CommonStoreExtraArgs {
  serverApi: Api
  platformAPIs: IPlatformAPI
  fedopsLogger: FedopsLogger
}

export interface Navigation extends RouteParams {
  sectionPath: string
  slug: string
  route: DETAILS_ROUTE
}

export interface RouteParams {
  reservationId?: string
  reservationState?: ReservationState
  thankYouMessageState?: ThankYouMessageState
}

export type ThankYouMessageState = 'yes' | 'no' | 'waiting'

export enum ReservationState {
  SUCCESS = 'success',
  ERROR = 'error',
  PENDING = 'pending',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
}

export interface Component {
  id: string
  settings: ComponentSettings
}

export interface ComponentSettings {
  texts: ComponentTexts
  imageRatio: ImageRatio
  buttonStyle: ButtonStyle
  formButtonStyle: ButtonStyle
  imagePosition: ImagePosition
  headerAlignment: Alignment
  contentAlignment: Alignment
  imageOpacity: number
  socialShareVisible: boolean
  aboutSectionVisible: boolean
  shortDateLocationVisible: boolean
  descriptionVisible: boolean
  mapVisible: boolean
  summaryBoxVisible: boolean
  rsvpButtonVisible: boolean
  membersVisible: boolean
  membershipsVisible: boolean
}

export interface CurrentMemberDetails {
  firstName: string
  lastName: string
  email: string
  id: string
}

export interface MemberRsvp {
  rsvp: wix.events.rsvp.Rsvp
  error: RsvpErrorMetadata
  loaded: boolean
}

export interface CheckoutOptionsState {
  loaded: boolean
  options: wix.events.ticketing.GetCheckoutOptionsResponse
}

export interface SelectedTickets {
  [id: string]: number
}

export interface PendingRequests {
  requestNames: string[]
}

export interface TicketsPicker {
  tickets: {[ticketDefId: string]: TicketsPickerTicketInfo}
}

interface TicketsPickerTicketInfo {
  expandedDescription?: boolean
}
